<!-- 我的课程模块 [学生 / 老师 / 专家 / 助教] -->
<template>
  <div class="center-situation">
    <div class="course_list wrapper">
      <proTitle></proTitle>
      <div class="internet-list-wrapper" v-if="internetCourseList && internetCourseList.length != 0">
        <ul class="internet-list">
          <li class="flex-layout internet-list-li" v-for="item in internetCourseList" :key="item.relId">
            <img src="../../assets/images/course/internet.png" alt="" class="internet-li-img">
            <div class="internet-li-info flex-column-between">
              <div>
                <h5 class="wes" style="width:100%">{{item.developmentLevel}}学习</h5>
                <p class="internte-projectName">{{$store.state.user.currentProject.name}}</p>
              </div>
              <div class="flex-align-between">
                <div>
                  <img src="../../assets/images/course/clock.png" alt="" class="internet-clock">
                  <span class="internet-time">学习总时间：<i class="blue">{{Math.ceil(item.totalLearn)}}分钟</i></span>
                </div>
                <button class="internet-btn csp" @click="enterLearning(item)">进入学习</button>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <Empty :show="internetCourseList && internetCourseList.length === 0"></Empty>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        internetCourseList: [], //课程列表
        pages: { //分页
          currentPage: 1,
          pageSize: 6,
          total: 0,
        },
      };
    },
    watch: {
      '$store.state.user.currentProject.id': {
        handler(newVal, oldVal) {
          if (!newVal) return;
          console.log(111)
          this.pages.currentPage = 1;
          this.getInternetCourseList();
        }
      }
    },
    methods: {
      // 翻页
      handlePagesData(data) {
        // console.log('handleListData',data)
        this.pages.currentPage = data.currentPage
        this.pages.pageSize = data.pageSize
        this.getInternetCourseList();
      },
      //获取项目下课程列表
      async getInternetCourseList() {
        if (this.$store.state.user.currentProject.id === "0") return;
        let params = {
          projectId: this.$store.state.user.currentProject.id,
        }
        let resData = await this.$Api.Home.getInternetCourseList(params)
        // console.log(resData)
        if (JSON.stringify(resData.data) == '[]' || !resData.data) {
          this.internetCourseList = [];
          return;
        }
        this.internetCourseList = resData.data;
      },
      // 去学习的页面
      enterLearning(item) {
        this.$Api.Home.getAuthKey({
          id: item.id,
          // relId: item.relId,
          projectId: this.$store.state.user.currentProject.id
        }).then(resData => {
          // console.log(resData);
          window.open(
            `${resData.data.url}&ticket=${resData.data.ticket}&platform=pc${!resData.data.paramString ? '':resData.data.paramString}`
          );
        }).catch(err => {
          console.log(err);
        })
      },
    },
    mounted() {
      this.getInternetCourseList();
    },
    activated() {},
  };
</script>

<style lang="less" scoped>
  @import "./less/course.less";

  .internet-list-wrapper {
    min-height: 500px;
    padding: 10px 0 33px;

    .internet-list {
      // padding: 0 41px;
    }

    .internet-list-li {
      margin-bottom: 35px;
    }

    .internet-li-img {
      width: 327px;
      flex-shrink: 0;
    }

    .internet-li-info {
      margin-left: 34px;
      padding: 11px 0;
      flex: 1;

      h5 {
        font-size: 15px;
        font-weight: 600;
        margin-bottom: 12px;
      }

      .internte-projectName {
        font-size: 12px;
        color: #999;
      }

      .internet-clock {
        margin-right: 9px;
        display: inline-block;
        vertical-align: -4px;
      }

      .internet-time {
        font-size: 12px;
        color: #666;

        .blue {
          color: #508EF9;
        }
      }

      .internet-btn {
        font-size: 12px;
        font-weight: 500;
        color: #fff;
        line-height: 30px;
        padding: 0 23px;
        background-color: #508EF9;
        border-radius: 25px;
      }
    }

    @media screen and (min-width:1250px) {
      padding: 20px 0 33px;

      .internet-list {
        // padding: 0 50px;
      }

      .internet-li-img {
        width: 394px;
      }

      .internet-li-info {
        margin-left: 41px;
        padding: 13px 0;

        h5 {
          font-size: 18px;
          margin-bottom: 14px;
        }

        .internet-clock {
          margin-right: 11px;
        }

        .internet-time {
          font-size: 14px;
        }

        .internet-btn {
          font-size: 15px;
          line-height: 36px;
          padding: 0 27px;
        }
      }
    }
  }
</style>